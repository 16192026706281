var render = function () {
  var _vm$selectedOption;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isModalVisible ? _c('div', {
    staticClass: "fixed inset-0 z-50 flex items-center add-content justify-center"
  }, [_c('div', {
    staticClass: "absolute inset-0 bg-black opacity-70",
    on: {
      "click": _vm.onToggle
    }
  }), _c('div', {
    staticClass: "relative w-full max-w-lg p-3 bg-white rounded-xl shadow-lg z-10"
  }, [_c('div', {
    staticClass: "flex justify-between px-3"
  }, [_c('label', {
    staticClass: "font-semibold text-base"
  }, [_vm._v("Add Content")]), _c('span', {
    on: {
      "click": _vm.onToggle
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-xmark text-gray-500 text-base cursor-pointer"
  })])]), _c('div', {
    staticClass: "text-left py-2 px-3 flex-auto justify-center leading-6"
  }, [_c('label', {
    staticClass: "text-left"
  }, [_vm._v("Content Type")]), _c('div', {
    staticClass: "relative inline-block text-left w-full"
  }, [_c('button', {
    staticClass: "flex w-full mb-1 text-left h-12 justify-between rounded-md border items-center border-gray-500 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
    class: this.errors.selectedOption ? 'border-red-500 border-red-custom ' : '',
    attrs: {
      "type": "button",
      "id": "dropdownButton",
      "aria-expanded": "false",
      "aria-haspopup": "true"
    },
    on: {
      "click": _vm.toggleDropdown
    }
  }, [_vm._v(" " + _vm._s((_vm$selectedOption = _vm.selectedOption) !== null && _vm$selectedOption !== void 0 ? _vm$selectedOption : 'Select an option') + " "), _vm.isDropdownOpen ? _c('svg', {
    staticClass: "-mr-1 ml-2 h-5 w-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M19 9l-7 7-7-7"
    }
  })]) : _c('svg', {
    staticClass: "-mr-1 ml-2 h-5 rotate-180 w-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M19 9l-7 7-7-7"
    }
  })])]), _vm.errors.selectedOption ? _c('div', {
    staticClass: "text-red-500 text-sm mt-2"
  }, [_vm._v(_vm._s(_vm.errors.selectedOption))]) : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDropdownOpen,
      expression: "isDropdownOpen"
    }],
    staticClass: "absolute w-full right-0 z-50 mt-2 border origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
    attrs: {
      "role": "menu",
      "aria-orientation": "vertical",
      "aria-labelledby": "dropdownButton"
    }
  }, [_c('div', {
    staticClass: "py-3 w-full flex-col px-3",
    attrs: {
      "role": "none"
    }
  }, [_c('div', {
    staticClass: "flex items-center mb-1 w-full overflow-hidden"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedOption,
      expression: "selectedOption"
    }],
    staticClass: "h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300",
    attrs: {
      "id": "book",
      "type": "radio",
      "name": "option",
      "value": "book"
    },
    domProps: {
      "checked": _vm._q(_vm.selectedOption, "book")
    },
    on: {
      "click": function ($event) {
        return _vm.selectOption('book');
      },
      "change": [function ($event) {
        _vm.selectedOption = "book";
      }, function ($event) {
        return _vm.optionType();
      }]
    }
  }), _c('label', {
    staticClass: "ml-3 mb-0 text-sm font-medium text-gray-700 pointer-events-none",
    attrs: {
      "for": "book"
    }
  }, [_vm._v(" Book ")])]), _c('div', {
    staticClass: "flex items-center w-full mb-1 overflow-hidden"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedOption,
      expression: "selectedOption"
    }],
    staticClass: "h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300",
    attrs: {
      "id": "magazine",
      "type": "radio",
      "name": "option",
      "value": "magazine"
    },
    domProps: {
      "checked": _vm._q(_vm.selectedOption, "magazine")
    },
    on: {
      "click": function ($event) {
        return _vm.selectOption('magazine');
      },
      "change": [function ($event) {
        _vm.selectedOption = "magazine";
      }, function ($event) {
        return _vm.optionType();
      }]
    }
  }), _c('label', {
    staticClass: "ml-3 text-sm font-medium mb-0 text-gray-700 pointer-events-none",
    attrs: {
      "for": "book"
    }
  }, [_vm._v(" Magazine ")])]), _c('div', {
    staticClass: "flex items-center w-full overflow-hidden"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedOption,
      expression: "selectedOption"
    }],
    staticClass: "h-4 w-4 text-blue-600 focus:ring-blue-300 border-gray-300",
    attrs: {
      "id": "newspapers",
      "type": "radio",
      "name": "option",
      "value": "newspaper"
    },
    domProps: {
      "checked": _vm._q(_vm.selectedOption, "newspaper")
    },
    on: {
      "click": function ($event) {
        return _vm.selectOption('newspaper');
      },
      "change": [function ($event) {
        _vm.selectedOption = "newspaper";
      }, function ($event) {
        return _vm.optionType();
      }]
    }
  }), _c('label', {
    staticClass: "ml-3 text-sm mb-0 font-medium text-gray-700 pointer-events-none",
    attrs: {
      "for": "book"
    }
  }, [_vm._v(" Newspapers ")])])])])]), _c('div', {
    staticClass: "mt-4",
    on: {
      "click": function ($event) {
        _vm.isDropdownOpen = false;
      }
    }
  }, [_c('label', {
    staticClass: "text-left"
  }, [_c('label', {
    staticClass: "text-left"
  }, [_vm._v("Content Type")])]), _c('multiselect', {
    staticClass: "custom-multiselect",
    class: this.errors.selectedOption ? 'border-red-500 border-red-custom ' : '',
    attrs: {
      "placeholder": "e.g.: Atomic Habits",
      "open-direction": "bottom",
      "label": "name",
      "track-by": "name",
      "options": _vm.optionsSearchFieldName,
      "loading": _vm.isNameSearch,
      "searchable": true,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": true
    },
    on: {
      "search-change": _vm.FindSearch
    },
    model: {
      value: _vm.itemsFieldName,
      callback: function ($$v) {
        _vm.itemsFieldName = $$v;
      },
      expression: "itemsFieldName"
    }
  }), _vm.errors.itemsFieldName ? _c('div', {
    staticClass: "text-red-500 text-sm mt-2"
  }, [_vm._v(_vm._s(_vm.errors.itemsFieldName))]) : _vm._e()], 1)], 1)]), _c('div', {
    staticClass: "px-3 mt-0 text-left space-x-4 md:block"
  }, [_c('button', {
    staticClass: "mb-2 md:mb-0 bg-blue-500 px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-white rounded-md hover:shadow-lg",
    on: {
      "click": _vm.AddContent
    }
  }, [_vm._v(" Save ")]), _c('button', {
    staticClass: "mb-2 md:mb-0 bg-white border border-purple-500 px-3 py-2 text-sm shadow-sm font-medium tracking-wider text-gray-500 rounded-md hover:shadow-lg",
    on: {
      "click": _vm.onToggle
    }
  }, [_vm._v(" Cancel ")])])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }