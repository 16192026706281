var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "relative overflow-x-auto mt-4 mb-4 rounded-sm shadow-sm bg-white p-4"
  }, [_c('div', {
    staticClass: "flex justify-end mb-4"
  }, [_c('button', {
    staticClass: "p-2 border-custom text-blue-500 rounded-lg",
    on: {
      "click": function ($event) {
        _vm.showModal = true;
      }
    }
  }, [_c('span', [_vm._v("+ Add Content")])]), _c('ModalAddContent', {
    attrs: {
      "isModalVisible": _vm.showModal
    },
    on: {
      "update:isModalVisible": function ($event) {
        _vm.showModal = $event;
      },
      "update:is-modal-visible": function ($event) {
        _vm.showModal = $event;
      }
    }
  }), _c('button', {
    staticClass: "ml-3 p-2 border text-gray-500 rounded-lg",
    on: {
      "click": _vm.generateData
    }
  }, [_c('span', [_vm._v("Sync Latest Data")])])], 1), _vm.isLoading ? _c('div', {
    staticClass: "mt-5 flex justify-center"
  }, [_c('div', {
    staticClass: "text-center text-dark my-2 w-full bg-white",
    staticStyle: {
      "height": "600px",
      "background-color": "rgb(204 204 204 / 39%)",
      "display": "flex",
      "border-radius": "5px",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "transform": "translate(-50%, -50%)"
    }
  }, [_c('b-spinner', {
    staticClass: "align-middle",
    attrs: {
      "show": _vm.isLoading,
      "variant": "dark",
      "type": "grow",
      "label": "Loading .."
    }
  }), _vm.isLoading ? _c('span', [_vm._v("Loading ..")]) : _vm._e()], 1)])]) : _c('div', {
    staticClass: "mb-0 relative rounded-lg"
  }, [_c('div', {
    staticClass: "relative overflow-x-auto shadow-md scrollbar parent-table sm:rounded-lg",
    attrs: {
      "id": "style-1"
    }
  }, [_c('table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoading,
      expression: "!isLoading"
    }],
    staticClass: "w-full h-[400px] border-r border-l text-sm text-gray-500 text-left rtl:text-right bg-white dark:text-gray-400 drag-move rounded-lg",
    attrs: {
      "items": _vm.items
    }
  }, [_vm._m(2), _vm.items.length > 0 ? _c('draggable', _vm._b({
    staticClass: "cursor-move",
    attrs: {
      "element": "tbody",
      "items": _vm.items,
      "move": _vm.checkMove,
      "disabled": !_vm.enabled,
      "ghost-class": "ghost"
    },
    on: {
      "start": _vm.handleDragStart,
      "end": function ($event) {
        return _vm.handleDragEnd($event);
      }
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.items, function (element, index) {
    return _c('tr', {
      key: element.id,
      attrs: {
        "data-item-id": element.item_id
      }
    }, [_c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_c('span', {
      staticClass: "block"
    }, [_c('svg', {
      attrs: {
        "width": "12",
        "height": "14",
        "viewBox": "0 0 12 14",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('path', {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "d": "M0.879883 4.44001C0.879883 4.08654 1.16642 3.80001 1.51988 3.80001H10.4799C10.8333 3.80001 11.1199 4.08654 11.1199 4.44001C11.1199 4.79347 10.8333 5.08001 10.4799 5.08001H1.51988C1.16642 5.08001 0.879883 4.79347 0.879883 4.44001ZM0.879883 7.00001C0.879883 6.64654 1.16642 6.36001 1.51988 6.36001H10.4799C10.8333 6.36001 11.1199 6.64654 11.1199 7.00001C11.1199 7.35347 10.8333 7.64001 10.4799 7.64001H1.51988C1.16642 7.64001 0.879883 7.35347 0.879883 7.00001ZM0.879883 9.56001C0.879883 9.20654 1.16642 8.92001 1.51988 8.92001H10.4799C10.8333 8.92001 11.1199 9.20654 11.1199 9.56001C11.1199 9.91347 10.8333 10.2 10.4799 10.2H1.51988C1.16642 10.2 0.879883 9.91347 0.879883 9.56001Z",
        "fill": "#6B7280"
      }
    }), _c('path', {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "d": "M5.74981 0.716474C5.9051 0.561184 6.15688 0.561184 6.31217 0.716474L7.43687 1.84118C7.59216 1.99647 7.59216 2.24825 7.43687 2.40354C7.28158 2.55883 7.02981 2.55883 6.87452 2.40354L6.03099 1.56001L5.18746 2.40354C5.03217 2.55883 4.78039 2.55883 4.6251 2.40354C4.46981 2.24825 4.46981 1.99647 4.6251 1.84118L5.74981 0.716474Z",
        "fill": "#6B7280"
      }
    }), _c('path', {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "d": "M6.31246 13.2835C6.15717 13.4388 5.90539 13.4388 5.7501 13.2835L4.62539 12.1588C4.4701 12.0035 4.4701 11.7518 4.62539 11.5965C4.78068 11.4412 5.03246 11.4412 5.18775 11.5965L6.03128 12.44L6.87481 11.5965C7.0301 11.4412 7.28188 11.4412 7.43717 11.5965C7.59246 11.7518 7.59246 12.0035 7.43717 12.1588L6.31246 13.2835Z",
        "fill": "#6B7280"
      }
    })])])]), _c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(_vm._s(element.item_type))]), _c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(_vm._s(element.item_name))]), _c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(_vm._s(element.item_id))]), _c('td', [_c('span', {
      staticClass: "block cursor-pointer",
      on: {
        "click": function ($event) {
          return _vm.removeItem(element, index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash text-gray-500 cursor-pointer",
      staticStyle: {
        "font-size": "20px"
      },
      attrs: {
        "aria-hidden": "true"
      }
    })])])]);
  }), 0) : _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v("No items available")])])])], 1)]), _c('div', {
    staticClass: "flex justify-start w-full"
  }, [_c('button', {
    staticClass: "bg-blue-500 px-3 py-2 rounded-lg text-white mt-0 mb-0",
    on: {
      "click": _vm.SaveAndUpdate
    }
  }, [_vm._v("Save & Submit")]), _c('button', {
    staticClass: "bg-white px-3 py-2 rounded-lg text-gray-600 mt-0 mb-0 border ml-2",
    on: {
      "click": _vm.handleBackClick
    }
  }, [_vm._v("Back")])])])]), _vm.toastVisible ? _c('Toast', {
    attrs: {
      "message": _vm.toastMessage,
      "type": _vm.toastType
    }
  }) : _vm._e(), _c('ModalDeleteContent', {
    attrs: {
      "isModalVisibleDelete": _vm.showModalDelete,
      "isOpenDelete": _vm.showModalDelete
    },
    on: {
      "update:isModalVisibleDelete": function ($event) {
        _vm.showModalDelete = $event;
      },
      "update:is-modal-visible-delete": function ($event) {
        _vm.showModalDelete = $event;
      },
      "close": _vm.closeModal,
      "confirm": _vm.confirmDelete
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white p-4 text-gray-500 my-4 rounded-lg shadow-sm"
  }, [_c('span', {
    staticClass: "py-3 text-gray-800"
  }, [_vm._v(" Content Recommendation "), _c('i', {
    staticClass: "fa fa-angle-right",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Edit "), _c('h5', {
    staticClass: "mt-1 text-2xl"
  }, [_vm._v("Edit Content Recommendation")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-4 bg-white rounded-lg px-4 py-3 w-full flex justify-start shadow-sm"
  }, [_c('div', {
    staticClass: "mr-3 flex justify-start items-center"
  }, [_c('div', {
    staticClass: "rounded-full w-7 h-7 border p-1 justify-center text-sm flex"
  }, [_vm._v("1")]), _c('span', {
    staticClass: "text-gray-600 ml-3"
  }, [_vm._v("Content Recommendation Detail")])]), _c('div', {
    staticClass: "mr-3 flex justify-start items-center"
  }, [_c('div', {
    staticClass: "rounded-full border-custom w-7 h-7 p-1 justify-center text-sm flex font-semibold text-blue-500"
  }, [_vm._v(" 2")]), _c('span', {
    staticClass: "ml-3 text-blue-500 font-semibold"
  }, [_vm._v("Content Selection")])]), _c('span')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }), _c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Item Type")]), _c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Item Name")]), _c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Items Id")]), _c('th')])]);
}]

export { render, staticRenderFns }