<template>
  <transition name="fade">
    <div v-if="isModalVisible" class="fixed inset-0 z-50 flex items-center add-content justify-center">
      <div @click="onToggle" class="absolute inset-0 bg-black opacity-70"></div>
      <div class="relative w-full max-w-lg p-3 bg-white rounded-xl shadow-lg z-10">
      <div class="flex justify-between px-3">
        <label class="font-semibold text-base ">Add Content</label>
        <span @click="onToggle"><i class="fa-solid fa-xmark text-gray-500 text-base cursor-pointer"></i></span>
      </div>
        <div class="text-left py-2 px-3 flex-auto justify-center leading-6">
          <!-- Flowbite Dropdown -->
          <label class="text-left">Content Type</label>
          <div class="relative inline-block text-left w-full ">
            <button type="button"
              class="flex w-full mb-1 text-left h-12 justify-between rounded-md border items-center border-gray-500 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
               :class="this.errors.selectedOption ? 'border-red-500 border-red-custom ': ''"
              id="dropdownButton" aria-expanded="false" aria-haspopup="true" @click="toggleDropdown">
              {{ selectedOption ?? 'Select an option' }}
              <svg v-if="isDropdownOpen" class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-else class="-mr-1 ml-2 h-5 rotate-180 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            <div v-if="errors.selectedOption" class="text-red-500 text-sm mt-2">{{ errors.selectedOption }}</div>

            <transition name="fade">
              <div v-show="isDropdownOpen"
                class="absolute w-full right-0 z-50 mt-2 border origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu" aria-orientation="vertical" aria-labelledby="dropdownButton">
                <div class="py-3 w-full flex-col px-3" role="none">
                 
                  <div class="flex items-center mb-1 w-full overflow-hidden">
                    <input id="book" type="radio" name="option" value="book" v-model="selectedOption"
                      class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300" @click="selectOption('book')"
                      @change="optionType()" />
                    <label for="book" class="ml-3 mb-0 text-sm font-medium text-gray-700 pointer-events-none">
                      Book
                    </label>
                  </div>
                  <div class="flex items-center w-full mb-1 overflow-hidden">
                    <input id="magazine" type="radio" name="option" value="magazine" v-model="selectedOption"
                      class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                      @click="selectOption('magazine')" @change="optionType()" />
                    <label for="book" class="ml-3 text-sm font-medium mb-0 text-gray-700 pointer-events-none">
                      Magazine
                    </label>
                  </div>
                  <div class="flex items-center w-full overflow-hidden">
                    <input id="newspapers" type="radio" name="option" value="newspaper" v-model="selectedOption"
                      class="h-4 w-4 text-blue-600 focus:ring-blue-300 border-gray-300"
                      @click="selectOption('newspaper')" @change="optionType()" />
                    <label for="book" class="ml-3 text-sm mb-0 font-medium text-gray-700 pointer-events-none">
                      Newspapers
                    </label>
                  </div>

                </div>
              </div>
            </transition>
            <div class="mt-4" @click="isDropdownOpen = false">
              <label class="text-left"> <label class="text-left">Content Type</label></label>
              <multiselect placeholder="e.g.: Atomic Habits" open-direction="bottom" v-model="itemsFieldName" label="name"
                track-by="name" :options="optionsSearchFieldName" :loading="isNameSearch" :searchable="true"
                :class="this.errors.selectedOption ? 'border-red-500 border-red-custom ': ''"
                :close-on-select="true" :options-limit="10" :multiple="true" class="custom-multiselect"
                @search-change="FindSearch">
              </multiselect>
              <div v-if="errors.itemsFieldName" class="text-red-500 text-sm mt-2">{{ errors.itemsFieldName }}</div>
       
            </div>
          </div>
        </div>
        <div class="px-3 mt-0 text-left space-x-4 md:block">
          <button @click="AddContent"
            class="mb-2 md:mb-0 bg-blue-500 px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-white rounded-md hover:shadow-lg">
            Save
          </button>
          <button @click="onToggle"
            class="mb-2 md:mb-0 bg-white border border-purple-500 px-3 py-2 text-sm shadow-sm font-medium tracking-wider text-gray-500 rounded-md hover:shadow-lg">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import 'flowbite';
import 'flowbite/dist/flowbite.css';
import {EventBus} from '../../../event-bus'
import { mapActions, mapMutations } from "vuex";
export default {
  props: {
    isModalVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isDropdownOpen: false,
      itemId: parseInt(this.$route.params.id),
      selectedOption: null,
      isNameSearch: false,
      itemsFieldName: [],
      optionsSearchFieldName: [],
      errors: {
        selectedOption: null,
        itemsFieldName: null
      }
    };
  },
  
  methods: {
    ...mapActions("content", ["getSearchItemContent",'checkDuplicate']),
    ...mapMutations('content', ['deleteItem', 'addArrayItems']),

    onToggle() {
      this.$emit('update:isModalVisible', !this.isModalVisible);
      this.optionType()
      this.isDropdownOpen = false
      this.selectedOption = null
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.optionsSearchFieldName = []
      this.isDropdownOpen = false;
      this.errors.selectedOption = null;
    },
    AddContent() {
      this.errors.selectedOption = this.selectedOption ? null : 'Must be selected.';
      this.errors.itemsFieldName = this.itemsFieldName.length > 0 ? null : 'Must be filled.';

      if (!this.errors.selectedOption && !this.errors.itemsFieldName) {
        const data = this.itemsFieldName.map(item => ({
          item_id: item.id,
          item_type: this.selectedOption,
          item_name: item.name
        }));

        this.checkDuplicate(data).then(duplicates => {
          if (duplicates && duplicates.length > 0) {
            EventBus.$emit('show-toast', {
          message: `Item Name ${duplicates.map(item => item.item_name).join(', ')} with 
          Item Id: ${duplicates.map(item => item.item_id).join(', ')} have already been added.`,
          type: 'error',
        });
          }

          this.$emit('update:isModalVisible', !this.isModalVisible);
          this.optionType();
        }).catch(error => {
          console.error('Error dispatching action:', error);
        });
      }
    },
   
    optionType() {
      this.optionsSearchFieldName = []
      this.itemsFieldName = []
    },
    FindSearch(query) {
    
      if (!query) return;
      clearTimeout(this.debounce)
      const payload = {
        id: this.itemId,
        type: this.selectedOption,
        query: query
      };
    
      this.isNameSearch = true;
      if(this.itemsFieldName ==[]) {
        this.errors.itemsFieldName = 'Must be filled.';
      }else {
        this.errors.itemsFieldName = null
      }
      
      this.debounce = setTimeout(() => {
        this.getSearchItemContent(payload).then((response) => {
          this.optionsSearchFieldName = response.data.data.rows.map(item => ({
            name: item.item_name,
            id: item.item_id
          }));
          this.isNameSearch = false;
          this.errors.itemsFieldName = null
        }).catch(() => {
          this.isNameSearch = false;
        });
      }, 600);
    },
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.add-content .multiselect {
  min-height: 176px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  width: 99%;
}

.add-content .multiselect__tag {
  background: #93c5fd;
  color: #2563eb;

}

.add-content .multiselect__tags {
  border: 0px;
}
.border-red-custom {
    border: 1px solid #ef4444  !important;
}

.add-content .multiselect input[type='text']:focus {
  outline-style: none;
}

.add-content .multiselect [type='text']:focus {
  outline-offset: 0px;
  offset: var(--tw-ring-offset-color);
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: var(--tw-ring-offset-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: unset;
}

.add-content .multiselect__content-wrapper {
  overflow-y: scroll;
  top: 190px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-x: hidden;
}

.add-content .multiselect__content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #fff;
  background-color: #fff;
}

.add-content .multiselect__content-wrapper::-webkit-scrollbar {
  width: 2px;
  background-color: #fff;
}

.add-content .multiselect__content-wrapper::-webkit-scrollbar-thumb {
  background-color: #2563eb;
  border: 3px solid #333;
}

.add-content .multiselect__content-wrapper .multiselect__option--highlight {
  background-color: #2563eb;
  width: 100%;
}

.add-content .multiselect__option--highlight {}

.add-content .multiselect__content {
  max-width: max-content;
  font-size: 14px;
}

.add-content .multiselect__option--highlight {
  position: relative;
}

.add-content .multiselect__select:before {
  display: none;
}
</style>